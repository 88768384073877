body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
}

body {
background: #121212;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 2px solid #ff4081;
  background-color: #1d1d1d;
}

header h1 {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #ff4081;
}

header nav {
  display: flex;
  gap: 1rem;
  align-items: center;
}

header nav a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

header nav a:hover {
  color: #ff4081;
}

header .icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.container {
  width: 100%;
  flex: 1;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-section, .preview-section {
  border: 1px solid #ff4081;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff4081;
  text-align: center;
  border-radius: 15px;
  margin: 1rem 0;
}

.preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px; /* Adjust this height as needed */
  width: 100%;
}

.upload-section {
  margin-bottom: 20px;
}

.upload-container {
  width: 350px;
  height: 500px;
  border: 2px solid #ff4081;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4081;
  cursor: pointer;
  text-align: center;
  position: relative;
  background-color: #121212;
}

.upload-section input {
  display: none;
}

.hiddenInput {
  display: none;
}

.upload-label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #ff4081;
  border-radius: 15px;
  text-align: center;
  color: #ff4081;
}

.image-preview {
  width: calc(100% - 20px); /* Add padding */
  height: calc(100% - 20px); /* Add padding */
  object-fit: contain;
  border-radius: 15px;
  padding: 10px;
  background-color: #121212; /* Match background color to avoid visual gaps */
}

.image-container {
  max-width: 300px;
  max-height: 400px;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #ff4081;
  margin: 1rem;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  max-height: 100%;
  object-fit: contain;
}

footer {
  background-color: #1d1d1d;
  color: #fff;
  padding: 2rem;
  text-align: center;
  border-top: 1px solid #ff4081;
  position: relative;
}

footer a {
  color: #ff4081;
  text-decoration: none;
  margin: 0 1rem;
}

footer a:hover {
  text-decoration: underline;
}


/* Enhanced Styles */
.buy-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem; /* Add space between the items */
  margin: 2rem 0;
}

.buy-button-wrapper {
  flex: 0 1 auto;
  margin: 1rem;
  border: 1px solid #ff4081;
  border-radius: 15px;
  padding: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.buy-button-wrapper:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.heading {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(135deg, #ff4081 30%, #ff80ab 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin-bottom: 2rem;
}

.subheading {
  font-size: 1.25rem; /* Make the subheading smaller */
  font-weight: 400;
  text-align: center;
  color: #ff80ab;
  margin-bottom: 1rem;
}

.get-tokens-page, .landing-page {
  background-color: #1d1d1d;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 2rem;
}

.relative.inline-block.overflow-hidden {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  perspective: 780px;
  margin: auto; /* Center loader horizontally */
  margin-top: 200px;
  margin-bottom: 200px;
}

.text {
  font-size: 20px;
  font-weight: 700;
  color: #cecece;
  z-index: 10;
}

.load-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
}

.load-inner.load-one {
  left: 0%;
  top: 0%;
  border-bottom: 3px solid #5c5edc;
  animation: rotate1 1.15s linear infinite;
}

.load-inner.load-two {
  right: 0%;
  top: 0%;
  border-right: 3px solid #9147ff;
  animation: rotate2 1.15s 0.1s linear infinite;
}

.load-inner.load-three {
  right: 0%;
  bottom: 0%;
  border-top: 3px solid #3b82f6;
  animation: rotate3 1.15s 0.15s linear infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotateX(45deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(45deg) rotateY(45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(45deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotateX(-60deg) rotateY(0deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(-60deg) rotateY(0deg) rotateZ(360deg);
  }
}

.custom-button {
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid #ff4081;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  transition: all 0.2s ease-in;
}

.custom-button:hover {
  background: #ff4081;
  box-shadow: 0 0 30px 5px #ff4081;
  transition: all 0.2s ease-out;
}

.custom-button:hover::before {
  animation: sh02 0.5s 0s linear;
}

.custom-button::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.custom-button:active {
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.2s ease-in;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 15px;
  z-index: 9999;
}

.cookie-banner p {
  margin: 0;
  padding: 0 10px;
  display: inline-block;
}

.cookie-banner button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #4CAF50;
  border: none;
  color: white;
  cursor: pointer;
}

.cookie-banner button:hover {
  background-color: #45a049;
}
