/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');*/

/* General styles */
.luxury-container {
  text-align: center;
  color: #e0e0e0;
  padding: 64px 0;
  font-family: 'Roboto', sans-serif;
}

.luxury-section {
  background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
  border-radius: 30px;
  padding: 64px;
  margin-bottom: 64px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
}

.luxury-button {
  background: linear-gradient(135deg, #b8860b 0%, #daa520 100%);
  color: #ffffff;
  padding: 12px 32px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 1rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}

.luxury-button:hover {
  background: linear-gradient(135deg, #daa520 0%, #b8860b 100%);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(218, 165, 32, 0.4);
}

.luxury-heading {
  font-weight: bold !important; /* TryOnAI in Bold */
  letter-spacing: +1px !important;
  color: #daa520; /* Goldene Farbe */
  font-size: 1.5rem; /* Schriftgröße etwas reduziert */
  margin-bottom: 16px;
  text-transform: none; /* All Caps entfernt */
  text-decoration: none; /* Unterstrich entfernen */
  font-family: 'Roboto', sans-serif !important; /* Roboto-Schriftart */
}

.luxury-subheading {
  color: #b0b0b0;
  margin-bottom: 32px;
  text-transform: uppercase; /* All Caps */
  font-family: 'Roboto', sans-serif !important; /* Roboto-Schriftart */
  font-weight: normal; /* Normale Schriftstärke */
  font-size: 1.5rem !important; /* Schriftgröße reduziert */
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 55.13%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.image-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.luxury-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Header styles */
.luxury-app-bar {
  background-color: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.luxury-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  color: #b0b0b0 !important;
}

.nav-link {
  color: #e0e0e0 !important;
  font-size: 1.1rem; /* Schriftgröße reduziert */
  letter-spacing: +1px;
  font-weight: normal; /* Normale Schriftstärke */
  margin-left: 32px; /* Abstand zwischen den Links erhöhen */
  text-transform: uppercase; /* All Caps */
  text-decoration: none; /* Keine Unterstreichung */
  font-family: 'Roboto', sans-serif; /* Roboto-Schriftart */
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #daa520 !important;
}

.token-balance {
  display: flex;
  align-items: center;
  margin-left: 24px;
  padding: 4px 12px;
  border-radius: 20px;
  background: linear-gradient(135deg, #2a2a2a 0%, #3a3a3a 100%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Footer styles */
.luxury-footer {
  background-color: #1a1a1a;
  color: #e0e0e0;
  padding: 64px 0;
  margin-top: 64px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-family: 'Roboto', sans-serif;
}

.footer-link {
  color: #daa520;
  text-decoration: none;
  transition: color 0.3s ease;
  font-family: 'Roboto', sans-serif;
}

.footer-link:hover {
  color: #b8860b;
  text-decoration: none;
}

.footer-section {
  margin-bottom: 32px;
}

.upload-container {
  width: 350px;
  height: 500px;
  border: 2px solid #daa520;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #daa520;
  cursor: pointer;
  text-align: center;
  position: relative;
  background-color: #1a1a1a;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
}

.upload-container:hover {
  border-color: #b8860b;
  box-shadow: 0 0 15px rgba(218, 165, 32, 0.3);
}

.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  margin: 0 20px;
  border: 2px solid #daa520;
  padding: 10px;
  background-color: #1a1a1a;
  font-family: 'Roboto', sans-serif;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.tooltip {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: 10px;
  background-color: #2a2a2a;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.profile {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #daa520;
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.user-info {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.about {
  color: #ccc;
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

.about ul {
  padding-left: 20px;
  margin: 0;
}
